<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo" :to="{ name: 'auth-login' }">
          <vuexy-logo />
          <h2 class="brand-text text-primary">BRXMIND</h2>
        </b-link>

        <b-card-title class="mb-1"> Resetar senha 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Por favor digite uma nova senha
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- password -->
            <b-form-group label="Nova senha" label-for="reset-password-new">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirmar senha"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button block type="submit" variant="primary">
              Salvar nova senha
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Voltar ao login
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      cPassword: "",
      password: "",
      // validation
      required,

      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },

    validationForm() {
      var code = this.$route.params.code;
      const decode = Buffer.from(code, "base64").toString("utf-8");

      var password = this.password;

      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios({
            method: "put",
            url: `${URL_API}reset-password?token=${decode}`,
            data: { password },
          })
            .then((response) => {
              if (response.data === "Invalid" || response.data === "Expired") {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Link expirado",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                    text: "Link expirado ou inválido, por favor tente novamente",
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$i18n.t("message.toast.saved"),
                    icon: "LockIcon",
                    variant: "success",
                    text: "Sua nova senha foi salva com sucesso, você será redirecionado(a) a tela de login",
                  },
                });
              }
            })
            .then(() => {
              this.loginTimer();
            });
        }
      });
    },

    loginTimer() {
      setTimeout(() => {
        this.$router.push({ name: "auth-login" });
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
